<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-login-on': this.state == 'login',
        'login-register-on': this.state == 'register',
        'login-forgot-on': this.state == 'forgot',
        'login-activation-on': this.state == 'activation',
        'login-reset-on': this.state == 'reset'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto max-w-lg-500px mb-20 mb-lg-0"
        style="background-color: #f5f5f5"
      >
        <div class="d-flex mb-auto flex-column flex-column pt-lg-40 pt-15 align-items-center">
          <a :href="homeUrl" class="h-55px mb-20">
            <img src="/media/svg/andlastly/logo.svg" class="max-h-70px w-auto h-55px" alt="" />
          </a>
          <h3 class="font-weight-bolder text-center font-size-h1 text-success w-75 pb-20">
            Safely prepare and send messages post-humously
          </h3>
        </div>
        <div
          class="aside-img d-none d-lg-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center max-h-50"
          :style="{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover'
          }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center justify-content-center position-relative overflow-hidden mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-md-center">
          <!--begin::login-->
          <div class="login-form login-login">
            <form class="form" novalidate="novalidate" id="kt_login_login_form" @submit.stop.prevent="onSubmitLogin()">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Welcome</h3>
                <span class="text-muted font-weight-bold font-size-h4"
                  >Are You New Here?
                  <a id="kt_login_register" class="text-primary font-weight-bolder" @click="showForm('register')"
                    >Create an Account</a
                  ></span
                >
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                  <a
                    href="#"
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click.prevent="showForm('forgot')"
                    >Forgot Password ?</a
                  >
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_login_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Sign In
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="/media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button> -->
              </div>
            </form>
          </div>
          <!--end::Login-->

          <!--begin::Register-->
          <div class="login-form login-register">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_register_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Sign Up</h3>
                <p class="text-muted font-weight-bold font-size-h4">Enter your details to create your account</p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  ref="fullname"
                  v-model="registerForm.display_name"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  v-model="registerForm.email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  v-model="registerForm.password"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_register_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4 w-150px"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_register_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('login')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::register-->

          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form class="form" novalidate="novalidate" id="kt_login_forgot_form" ref="kt_login_forgot_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Forgotten Password ?</h3>
                <p class="text-muted font-weight-bold font-size-h4">Enter your email to reset your password</p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="forgot_email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  @click="onSubmitForgot"
                  type="button"
                  id="kt_login_forgot_submit"
                  :class="apiBusy ? buttonSpinner : ''"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('login')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->

          <!--begin::Activation-->
          <div class="login-form login-activation">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_activation_form"
              @submit.stop.prevent="onSubmitActivation()"
              ref="kt_login_activation_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Activate your account</h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Copy and paste the activation key that was sent to your email address in the field below
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Activation key"
                  name="activation_key"
                  v-model="activationForm.key"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  ref="kt_login_activation_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4 w-150px"
                >
                  Activate
                </button>
                <button
                  type="button"
                  id="kt_login_activation_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('login')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Activation-->

          <!--begin::reset-->
          <div class="login-form login-reset">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_reset_form"
              @submit.stop.prevent="onSubmitReset()"
              ref="kt_login_reset_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Reset your password</h3>
                <p class="text-muted font-weight-bold font-size-h4">Enter your new password</p>
              </div>

              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password1"
                  ref="password1"
                  v-model="resetForm.password1"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Verify Password"
                  name="password2"
                  v-model="resetForm.password2"
                  autocomplete="off"
                />
              </div>

              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  :class="apiBusy ? buttonSpinner : ''"
                  ref="kt_login_reset_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4 w-200px"
                >
                  Reset Password
                </button>
                <button
                  type="button"
                  id="kt_login_reset_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('login')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::reset-->
        </div>
        <!--begin::Content footer-->
        <div class="d-flex justify-content-center mb-20 py-7 py-lg-0">
          <a href="https://andlastly.io" class="text-primary font-weight-bolder font-size-h5">About</a>
          <a href="https://andlastly.io/privacy/" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Privacy Policy</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">V{{ appVersion }}</a>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

import KTUtil from '@/assets/js/components/util';
import { mapGetters, mapState, mapActions } from 'vuex';
import { LOGIN, REGISTER, ACTIVATE, FORGOT, RESET } from '@/core/services/store/auth.module';
import Swal from 'sweetalert2';

export default {
  name: 'login-1',
  data() {
    return {
      state: 'login',
      // Remove this dummy login info
      form: {
        email: '',
        password: ''
      },

      registerForm: {
        display_name: '',
        email: '',
        password: ''
      },

      activationForm: {
        user_id: '',
        key: ''
      },

      forgotForm: {
        email: ''
      },

      resetForm: {
        password1: '',
        password2: ''
      }
    };
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),

    ...mapGetters(['currentUser', 'buttonSpinner', 'apiBusy', 'appVersion', 'homeUrl']),

    backgroundImage() {
      return process.env.BASE_URL + 'media/svg/illustrations/login-visual-4.svg';
    }
  },

  mounted() {
    // setup validation
    const login_form = KTUtil.getById('kt_login_login_form');
    const register_form = KTUtil.getById('kt_login_register_form');
    const forgot_form = KTUtil.getById('kt_login_forgot_form');
    const activation_form = KTUtil.getById('kt_login_activation_form');
    const reset_form = KTUtil.getById('kt_login_reset_form');

    this.fv = formValidation(login_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: 'Username is required'
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: 'Password is required'
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    // registration
    this.fv1 = formValidation(register_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: 'Full name is required'
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: 'Email is required'
            },
            emailAddress: {
              message: 'The value is not a valid email address'
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: 'Password is required'
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: 'Confirm password is required'
            },
            identical: {
              compare: function () {
                return register_form.querySelector('[name="password"]').value;
              },
              message: 'The password and its confirm are not the same'
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: 'You should agree terms and conditions'
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    // forgot password
    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: 'Email is required'
            },
            emailAddress: {
              message: 'The value is not a valid email address'
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    //
    this.fv3 = formValidation(activation_form, {
      fields: {
        activation_key: {
          validators: {
            notEmpty: {
              message: 'Activation code is required'
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    //
    this.fv4 = formValidation(reset_form, {
      fields: {
        password1: {
          validators: {
            notEmpty: {
              message: 'Password is required'
            }
          }
        },
        password2: {
          validators: {
            notEmpty: {
              message: 'Confirm password is required'
            },
            identical: {
              compare: function () {
                return reset_form.querySelector('[name="password1"]').value;
              },
              message: 'The password and its confirm are not the same'
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    // check which form to show
    const route = this.$route.name;

    if (route == 'activation.key') {
      this.activationForm.key = this.$route.params.key ?? '';

      console.log(this.activationForm);

      this.showForm('activation');
    } else {
      this.showForm(route);
    }
  },

  methods: {
    ...mapActions(['toast']),

    showForm(form) {
      this.state = form;
      var form_name = 'kt_login_' + form + '_form';
      KTUtil.animateClass(KTUtil.getById(form_name), 'animate__animated animate__backInUp');
    },

    onSubmitLogin() {
      this.fv
        .validate()

        .then(status => {
          if (status == 'Valid') {
            var email = this.form.email;
            var password = this.form.password;

            // set spinner to submit button
            const submitButton = this.$refs['kt_login_login_submit'];
            submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

            // send login request
            this.$store
              .dispatch(LOGIN, { username: email, password })
              // go to which page after successfully login
              .then(() => this.$router.push({ name: 'dashboard' }))
              .catch(() => {
                Swal.fire({
                  text: 'There was a problem signing you in.  Please try with a different username and password and make sure you have activated your account via email',
                  icon: 'error',
                  buttonsStyling: false,
                  heightAuto: false,
                  showCancelButton: false,
                  confirmButtonText: 'Try again',
                  customClass: {
                    confirmButton: 'btn font-weight-bold btn-light-primary',
                    cancelButton: 'btn font-weight-bold btn-default'
                  }
                });
              })

              .finally(() => {
                submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
              });
          } else {
            Swal.fire({
              title: '',
              text: 'Please, provide correct data',
              icon: 'error',
              customClass: {
                confirmButton: 'btn font-weight-bold btn-light-primary',
                cancelButton: 'btn font-weight-bold btn-default'
              },
              heightAuto: false
            });
          }
        });
    },

    onSubmitRegister() {
      this.fv1
        .validate()

        .then(status => {
          if (status == 'Valid') {
            // set spinner to submit button
            const submitButton = this.$refs['kt_login_register_submit'];
            submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

            // send register request
            this.$store
              .dispatch(REGISTER, this.registerForm)

              .then(() => {
                // show a popup
                Swal.fire({
                  text: 'Thank you for signing up.  Please check your emails for an activation key.',
                  icon: 'success',
                  buttonsStyling: false,
                  heightAuto: false,
                  showCancelButton: false,
                  confirmButtonText: 'Ok, activate account.',
                  customClass: {
                    confirmButton: 'btn font-weight-bold btn-light-primary',
                    cancelButton: 'btn font-weight-bold btn-default'
                  }
                }).then(() => {
                  this.showForm('activation');
                });
              })

              .catch(error => {
                Swal.fire({
                  text: error.message,
                  icon: 'error',
                  buttonsStyling: false,
                  heightAuto: false,
                  showCancelButton: false,
                  confirmButtonText: 'Ok, got it!',
                  customClass: {
                    confirmButton: 'btn font-weight-bold btn-light-primary',
                    cancelButton: 'btn font-weight-bold btn-default'
                  }
                });
              })

              .finally(() => {
                submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
              });
          } else {
            Swal.fire({
              title: '',
              text: 'Please, provide correct data!',
              icon: 'error',
              confirmButtonClass: 'btn btn-secondary',
              heightAuto: false
            });
          }
        });
    },

    onSubmitActivation() {
      this.fv3
        .validate()

        .then(status => {
          if (status == 'Valid') {
            // set spinner to submit button
            const submitButton = this.$refs['kt_login_activation_submit'];
            submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

            // dummy delay
            // send login request
            this.$store
              .dispatch(ACTIVATE, this.activationForm)
              // go to which page after successfully login
              .then(() => {
                Swal.fire({
                  text: 'Your account was successfully activated.  Please login with your username and password',
                  icon: 'success',
                  buttonsStyling: false,
                  heightAuto: false,
                  showCancelButton: false,
                  confirmButtonText: 'Ok, got to login!',
                  customClass: {
                    confirmButton: 'btn font-weight-bold btn-light-primary',
                    cancelButton: 'btn font-weight-bold btn-default'
                  }
                }).then(() => this.showForm('login'));
              })
              .catch(error => {
                Swal.fire({
                  text: error.message,
                  icon: 'error',
                  buttonsStyling: false,
                  heightAuto: false,
                  showCancelButton: false,
                  confirmButtonText: 'Ok, got it!',
                  customClass: {
                    confirmButton: 'btn font-weight-bold btn-light-primary',
                    cancelButton: 'btn font-weight-bold btn-default'
                  }
                });
              })

              .finally(() => {
                submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
              });
          } else {
            Swal.fire({
              title: '',
              text: 'Please, provide activation key',
              icon: 'error',
              confirmButtonClass: 'btn btn-secondary',
              heightAuto: false
            });
          }
        });
    },

    onSubmitForgot() {
      this.fv2
        .validate()

        .then(status => {
          if (status == 'Valid') {
            let email = this.$refs['forgot_email'].value;
            this.$store
              .dispatch(FORGOT, email)

              .then(() => {
                this.toast('Password reset instructions were sent via email');
              })

              .catch(error => {
                this.toast('There was an error while processing your request | ' + error.message);
              });
          }
        });
    },

    onSubmitReset() {
      this.fv4
        .validate()

        .then(result => {
          if (result == 'Valid') {
            let password = this.$refs['password1'].value;
            let key = this.$route.params.key;
            this.$store
              .dispatch(RESET, { password, key })

              .then(() => {
                this.toast('Password was successfully reset.  Please login');
                this.showForm('login');
              })

              .catch(error => {
                this.toast('Error |  ' + error.message);
              });
          }
        });
    }
  }
};
</script>
